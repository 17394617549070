import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const Nav = styled.nav`
  max-width: 1100px;
  font-family: 'ColumbiaRegular';
  font-size: 16px;
  margin: 0 auto;
  ul {
    display: flex;
    justify-content: space-around;
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    &:after {
      content: '';
      position: absolute;
      top: -18px;
      left: 70px;
      height: 3px;
      width: 83%;
      background: ${COLORS.primary};
      z-index: -1;
      @media (max-width: 940px) {
        content: none;
      }
    }
    li {
      position relative;
      text-align: center;
      padding: 0 20px;
      &:before {
        content: '';
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        height: 26px;
        width: 26px;
        background: ${COLORS.primary};
        border: 3px solid ${COLORS.primary};
        border-radius: 50%;
        box-sizing: border-box;
      }
      a, span {
        cursor: pointer;
        line-height: 40px;
        color: ${COLORS.text};
        text-decoration: none;
      }
      a {
        &:hover, &:focus {
          color: ${COLORS.text};
        }
      }
      span {
        cursor: default;
      }
      &.active {
        &:before {
          background: ${COLORS.primary};
          box-shadow: 0px 0px 0px 4px #fff inset;
        }
        a, span {
          font-family: 'ColumbiaBold';
        }
      }
    }
  }

  @media (max-width: 940px) {
    li:not(.active) {
      display: none;
    }
  }
`;
