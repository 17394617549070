import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import CheckMark from '../../assets/svgs/check-mark.svg';

export const Header = styled.div`
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
    img {
      margin-bottom: 20px;
    }
    button {
      margin-bottom: 70px;
    }
  }
`;

export const Container = styled.div`
  flex: auto;
  width: 800px;
  padding: 0 15px;
  margin: 0 auto;
  #primary-btn {
    align-self: self-end;
    margin: 30px 0 177px;
  }
  @media (max-width: 940px) {
    width: auto;
    #primary-btn {
      margin-bottom: 80px;
    }
  }
`;

export const AnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: 0 auto 15px;
  &>div {
    display: flex;
    flex-direction: column;
    &.risk-screening {
      flex-direction: row;
      button {
        height: 130px;
        width: 270px;
        text-align: center;
        &:first-child {
          margin-right: 30px;
        }
        &:after {
          border: none;
        }
        &.active {
          &:after {
            background: transparent;
            box-shadow: none;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    &>div {
      &.risk-screening {
        flex-direction: column;
        button {
          height: 70px;
          width: 470px;
          &:first-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    &>div {
      &.risk-screening {
        button {
          width: 100%;
          min-width: 300px;
          padding-right: 30px;
        }
      }
    }
  }
`;

export const Question = styled.div`
  position: relative;
  font-family: 'ColumbiaBold';
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  margin: 0 0 60px;
`;

export const Answer = styled.button`
  position: relative;
  height: 70px;
  width: 470px;
  font-family: 'ColumbiaBold';
  font-size: 22px;
  text-align: left;
  background: transparent;
  border: 1px solid ${COLORS.borderBtn};
  border-radius: 2px;
  padding: 0 30px;
  margin-bottom: 15px;
  cursor: pointer;
  &:after {
    content: '';
    height: 20px;
    width: 20px;
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translateY(-50%);
    border: 1px solid ${COLORS.borderBtn}; 
    border-radius: 2px;
  }
  &:hover, &:focus, &:active {
    outline: none;
  }
  &:hover {
    border-color: ${COLORS.primary};
    &:after {
      border-color: ${COLORS.primary};
    }
  }
  &.active {
    border: 6px solid ${COLORS.primary};
    &:after {
      background: 50% no-repeat url(${CheckMark}), ${COLORS.primary};
      border-color: ${COLORS.primary};
      box-shadow:0px 0px 0px 2px #fff inset;
    }
  }
  strong {
    display: block;
    line-height: 26px;
  }
  @media (max-width: 500px) {
    font-size: 20px;
    width: 100%;
    min-width: 300px;
    padding-right: 45px;
    &:after {
      height: 15px;
      width: 15px;
    }
    &.active {
      border-width: 2px;
    }
    small {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const Counter = styled.div`
  position: absolute;
  top: -4px;
  left: -100px;
  font-size: 40px;
  span {
    display: inline-block;
    color: ${COLORS.primary};
    margin: 0 5px;
  }
  small {
    font-family: 'ColumbiaBold';
    font-size: 16px;
  }
  @media (max-width: 1024px) {
    position: unset;
    margin-bottom: 20px;
  }
`;
