import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const Container = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 5px;
  &:hover {
    div {
      visibility: visible;
    }
  }
`;

export const TooltipText = styled.div`
  font-size: 12px;
  line-height: 20px;
  visibility: hidden;
  width: 366px;
  background-color: ${COLORS.highlightedText};
  color: #fff;
  padding: 6px 8PX;
  border-radius: 2px;
  position: absolute;
  top: 45px;
  z-index: 1;
`;

export const Icon = styled.span`
  display: inline-block;
  height: 14px;
  width: 14px;
  font-family: 'ColumbiaBold';
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  background: ${COLORS.primary};
  border-radius: 50%;
  cursor: default;
`;
