import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const Button = styled.button`
  min-width: 170px;
  font-family: 'ColumbiaBold';
  font-size: 16px;
  color: ${COLORS.highlightedText};
  text-align: center;
  background-color: ${COLORS.primary};
  border: none;
  border-radius: 2px;
  padding: 14px 42px;
  cursor: pointer;
  &.inactive {
    pointer-events: none;
    opacity: 50%;
  }
  &:hover {
    background-color: ${COLORS.primaryDarken};
  }
`;

export const BackBtn = styled.button`
  position: absolute;
  top: 40px;
  left: -600px;
  font-family: 'ColumbiaBold';
  font-size: 14px;
  background: transparent;
  border: none;
  margin-left: 70px;
  &:hover, &:focus {
    border-color: ${COLORS.primary};
    cursor: pointer;
    outline: none;
  }
  @media (max-width: 1200px) {
    left: -500px;
  }
  @media (max-width: 1000px) {
    left: -400px;
  }
  @media (max-width: 800px) {
    position: static;
    margin: 0 auto;
  }
`;
