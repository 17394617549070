import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';

import { Context } from '../../store';
import {
  generateResult,
  results,
  questionsList,
  emailResult
} from '../../constants/urls';
import { ExpiredLink, TooltipText } from '../../constants/texts';
import Logo from '../../components/logo/Logo';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import Tooltip from '../../components/tooltip/Tooltip';

import { CarePlanByRootType, ResourceType, ResponseType } from './interface';
import {
  Container,
  LinkExpiredContainer,
  H1, H2, H3,
  BoldText, Text,
  Section, Collapse,
  TopContainer,
  EmailContainer,
  EmailInput,
  SuccesMessage,
  ErrorMessage,
  EmailLoader
} from './styles';

const Result = () => {

  const history = useHistory();

  const [state, dispatch] = useContext(Context);

  const [carePlans, setCarePlans]: [
    CarePlanByRootType,
    React.Dispatch<React.SetStateAction<CarePlanByRootType>>
  ] = useState({} as CarePlanByRootType);

  const [resources, setResources]: [
    Array<ResourceType>,
    React.Dispatch<React.SetStateAction<Array<ResourceType>>>
  ] = useState([] as Array<ResourceType>);

  const [expandedCPs, setExpandedCPs]: [
    Array<number>,
    React.Dispatch<React.SetStateAction<Array<number>>>
  ] = useState([] as Array<number>);

  const [expandedRes, setExpandedRes]: [
    Array<string>,
    React.Dispatch<React.SetStateAction<Array<string>>>
  ] = useState([] as Array<string>);

  const [linkExpired, setLinkExpired] = useState(false);

  const [inputValue, setIputValue] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false);

  const setResults = (data: ResponseType) => {
    const carePlansByRoot = data.carePlans
      .sort((a: any, b: any) => a.root.order - b.root.order)
      .reduce((a: any, b: any) => {
        a[b.root.title] = [...a[b.root.title] || [b.root], b];
        return a;
      }, {});

    setCarePlans(carePlansByRoot);
    setResources(data.resources);
  };

  useEffect(() => {
    const data = state.questions.map((q: any, i: number)  => {
      return {
        question: {
          id: q.id,
          order: q.order,
        },
        choices: state.choices ?
          [...state.choices[i]].map((ch: number) => {
            return {
              id: q.answers[ch].id,
              title: q.answers[ch].title,
              tag: q.answers[ch].tag?.id,
            }
          }) : []
      }
    });

    if (data.length && state.choices) {
      axios.post(`${process.env.REACT_APP_API_URL}${generateResult}`, data)
        .then((response: AxiosResponse<ResponseType>) => {
          setResults(response.data);
          history.push(`?${response.data.result}`);
        })
    } else {
      axios.get(`${process.env.REACT_APP_API_URL}${questionsList}`)
      .then(response => {
        const questions = response.data.sort((a: any, b: any) => a.order - b.order);
        dispatch({ type: 'SET_QUESTIONS', payload: questions });
      })
      .catch(error => {
        dispatch({ type: 'SET_ERROR', payload: error });
      });

      if (history.location.search) {
        const resultId = history.location.search.split('?')[1];

        axios.get(`${process.env.REACT_APP_API_URL}${results}/${resultId}`)
        .then((response: AxiosResponse<ResponseType>) => {
          setResults(response.data);
          setLinkExpired(false);
        }).catch(err => {
          setLinkExpired(true);
        })
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const togglSectionCPs = (panel: number) => {
    const expanded = new Set(expandedCPs);
    if (expanded.has(panel)) {
      expanded.delete(panel);
    } else {
      expanded.add(panel)
    }
    setExpandedCPs([...expanded]);
  };

  const togglSectionRes = (panel: string) => {
    const expanded = new Set(expandedRes);
    if (expanded.has(panel)) {
      expanded.delete(panel);
    } else {
      expanded.add(panel)
    }
    setExpandedRes([...expanded]);
  };

  const startOver = () => {
    dispatch({ type: 'SET_CURRENT_QUESTION', payload: 0 });
    history.push('/questionnaire');
  }

  const visitLink = (resource: ResourceType) => {
    window.open(resource.link ? resource.link : resource.file, '_blank');
  }

  const handleChange = (event: any) => {
    setIputValue(event.target.value);
  }

  const sendEmail = () => {
    setError('');
    setSuccess(false);
    setEmailLoader(true);
    const resultId = history.location.search.split('?')[1];
    axios.post(`${process.env.REACT_APP_API_URL}${emailResult}`, {
      email: inputValue,
      resultId
    })
    .then((response: AxiosResponse<any>) => {
      setEmailLoader(false);
      setSuccess(true);
    }).catch((err) => {
      setEmailLoader(false);
      setError('Something went wrong. Please try again.');
    })
  }

  return (
    <>
      <div>
        <Logo />
      </div>
      {
        linkExpired ?
          <LinkExpiredContainer>
            <H1>Link Expired</H1>
            <Text>{ExpiredLink}</Text>
            <PrimaryButton
              title='Start Over'
              handleClick={startOver}
            />
          </LinkExpiredContainer> :
          <>
            <Container>
              <PrimaryButton
                title='Start Over'
                handleClick={startOver}
              />
              <TopContainer>
                <H1>Resources</H1>
                <EmailContainer>
                  <Tooltip
                    text={TooltipText}
                  />
                  <strong>Email link</strong>
                  <EmailInput
                    type='email'
                    placeholder='example@example.com'
                    onChange={handleChange}
                  />
                  <PrimaryButton
                    title='Send'
                    handleClick={sendEmail}
                  />
                  { emailLoader ?
                    <EmailLoader>
                      <div></div><div></div><div></div><div></div>
                    </EmailLoader> : ''
                  }
                  { success ?
                    <SuccesMessage>
                      Email is sent
                    </SuccesMessage> : ''
                  }
                  { error ?
                    <ErrorMessage>
                      {error}
                    </ErrorMessage> : ''
                  }
                  
                </EmailContainer>
              </TopContainer>
              { Object.keys(carePlans).map((key: any) => {
                return carePlans[key].map((cp: any) => {
                  if (cp.isRoot) {
                    return (
                      <div key={`careplan-${cp.id}`}>
                        <H2>{cp.title}</H2>
                        <BoldText>{cp.text}</BoldText>
                      </div>
                    )
                  } else {
                    return (
                      <Section key={`careplan-${cp.id}`}>
                        <H3
                          onClick={() => {togglSectionCPs(cp.id)}}
                        >
                          {cp.title}
                          <Collapse
                            className={`collapsed ${new Set(expandedCPs).has(cp.id) ? 'active' : ''}` }
                          ></Collapse>
                        </H3>
                        <Text
                        className={`collapsed ${new Set(expandedCPs).has(cp.id) ? 'active' : ''}` }
                        >
                          {cp.text}
                        </Text>
                      </Section>
                    )
                  }
                })
              })}
              { resources.length ?
              <>
                <br />
                <H2>Additional Resources</H2>
                { resources.map((r: any) => {
                  return (
                    <Section key={`resource-${r.id}`}>
                      <H3
                        onClick={() => {togglSectionRes(r.id)}}
                      >
                        {r.title}
                        <Collapse
                          className={`collapsed ${new Set(expandedRes).has(r.id) ? 'active' : ''}` }
                        ></Collapse>
                      </H3>
                      <Text
                        className={`collapsed ${new Set(expandedRes).has(r.id) ? 'active' : ''}` }
                      >
                        <div>
                          {r.description}
                        </div>
                        <PrimaryButton
                          title='Visit'
                          handleClick={() => { visitLink(r) }}
                        />
                      </Text>
                    </Section>
                  )
                })}
              </> : ''
            }
            </Container>
          </>
        }
    </>
  );
}

export default Result;
