export const QuestionsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_QUESTIONS':
      return {
        ...state,
        questions: action.payload
      };
    case 'SET_CURRENT_QUESTION':
      return {
        ...state,
        current: action.payload
      };
    case 'SET_CONTENT_TO_SHOW':
      return {
        ...state,
        contentToShow: action.payload
      };
    case 'SET_CHOICES':
      return {
        ...state,
        choices: action.payload
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
