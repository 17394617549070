import React from 'react';
import { ITooltipProps } from './interface';
import { Container, Icon, TooltipText } from './styles';

const Tooltip = ({
  text
}: ITooltipProps) => {

  return (
    <Container>
       <Icon>i</Icon>
       <TooltipText>{text}</TooltipText>
    </Container>
  );
};

export default Tooltip;
