import React from 'react';
import { IButtonProps } from './interface';
import { Button } from './styles';

const PrimaryButton = ({
  title,
  classes,
  handleClick
}: IButtonProps) => {
  return (
    <Button
      id='primary-btn'
      className={classes?.length ? classes.join(' ') : ''}
      onClick={handleClick}
    >
      {title}
    </Button>
  );
};

export default PrimaryButton;
