import React from 'react';
import { IBackBtnProps } from './interface';
import { BackBtn } from './styles';

const BackButton = ({
  title,
  handleClick
}: IBackBtnProps) => {
  return (
    <BackBtn
      onClick={handleClick}
    >
      &#9666; {title}
    </BackBtn>
  );
};

export default BackButton;
