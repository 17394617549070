import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const ContentWrap = styled.div`
  max-width: 770px;
  padding-bottom: 80px;
  margin: 0 auto;
  @media (max-width: 1025px) {
    padding-bottom: 0;
  }
  blockquote {
    font-family: 'ColumbiaBold';
    font-size: 16px;
    line-heignt: 26px;
    margin: 0 0 20px;
  }
  p {
    margin: 0 0 10px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 20px 0 10px;
  }
  a {
    font-family: 'ColumbiaBold';
    color: ${COLORS.link} !important;
    position: relative;
    &:after {
      content: '>>';
      position: absolute;
      right: -12px;
    }
    &:hover, &:active {
      text-decoration: none;
    }
  }
`;

export const H1 = styled.h1`
  margin-bottom: 40px;
`;
