import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import ArrowBack from '../../assets/svgs/arrow-back.svg';

export const Container = styled.div`
  max-width: 770px;
  padding-bottom: 60px;
  margin: 0 auto;
  &>button {
    position: absolute;
    top: 35px;
    min-width: 130px;
    padding: 14px 0;
    @media (max-width: 500px) {
      position: static;
      margin-bottom: 30px;
    }
  }
  @media (max-width: 1025px) {
    padding-bottom: 0;
  }
  @media (max-width: 800px) {
    padding: 0 15px;
  }
`;

export const LinkExpiredContainer = styled.div`
  min-height: 50vh;
  max-width: 1024px;
  padding-bottom: 60px;
  margin: 0 auto;
  h1 {
    margin-bottom: 40px;
  }
  button {
    width: 280px;
  }
  @media (max-width: 1025px) {
    padding-bottom: 0;
  }
`;

export const H1 = styled.h1`
  text-align: left;
  margin-bottom: 0;
`;

export const H2 = styled.h2`
  font-size: 24px;
  text-align: left;
  margin: 42px 0 18px;
`;

export const H3 = styled.h3`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 1.5;
  text-align: left;
  padding: 15px 0;
  margin: 0;
  cursor: pointer;
`;

export const Text = styled.div`
  padding-bottom: 22px;
  &.collapsed {
    display: none;
  }
  &.collapsed.active {
    display: block;
  }
  button {
    margin-top: 20px;
  }
`;

export const BoldText = styled.p`
  font-family: 'ColumbiaBold';
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
`;

export const Section = styled.div`
  border-bottom: 1px solid ${COLORS.separators};
  p {
    padding: 35px 64px;
    margin: 0;
  }
  button {
    min-width: 70px;
    padding: 10px;
  }
`;

export const Collapse = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  background: ${COLORS.primary};
  &.active {
    &:after {
      transform: rotate(270deg);
    }
  }
  &:after {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    background: 35% no-repeat url(${ArrowBack}), transparent;
    transform: rotate(180deg);
    transition: transform 0.6s;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    h1 {
      margin-top: 30px;
    }
  }
`;

export const EmailContainer = styled.div`
  position: relative;
  strong {
    font-size: 14px;
  }
  button {
    height: 40px;
    min-width: 70px;
    padding: 10px;
    margin-left: 10px;
  }
  @media (max-width: 440px) {
    margin-bottom: 20px;
    input, button {
      display: block;
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
`;

export const EmailInput = styled.input`
  height: 40px;
  width: 200px;
  fon-size: 14px;
  border: 1px solid ${COLORS.borderBtn};
  border-radius: 2px;
  padding: 0 10px;
  margin-left: 15px;
`;

export const SuccesMessage = styled.p`
  position: absolute;
  left: 98px;
  color: ${COLORS.link};
  margin: 0;
  @media (max-width: 440px) {
    left: 0;
  }
`;

export const ErrorMessage = styled.p`
  position: absolute;
  left: 98px;
  color: ${COLORS.errorText};
  margin: 0;
  @media (max-width: 440px) {
    left: 0;
  }
`;

export const EmailLoader = styled.div`
  position: absolute;
  left: 98px;
  top: 18px;
  div {
    position: absolute;
    top: 33px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${COLORS.primaryDarken};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  @media (max-width: 440px) {
    left: 0;
  }
`;
