import styled from 'styled-components';
import BgBR from '../../assets/svgs/background/bg-bottom-right.svg';

export const BgWrapper = styled.div`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 441px;
    width: 487px;
    background: 50% no-repeat url(${BgBR}), transparent;
    z-index: -1;
    @media (max-width: 800px) {
      background: transparent;
      width: auto;
    }
  }
`;

export const Container = styled.div`
  max-width: 570px;
  display: flex;
  flex-direction: column;
  padding: 50px 15px 0;
  margin: 0 auto;
  p {
    &:first-child {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
  button {
    margin: 20px 0 30px;
  }
`;

export const Logo = styled.img`
  height: auto;
  width: 291px;
  margin-bottom: 40px;
`;

export const Footer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 144px auto 58px;
  p {
    font-size: 12px;
    margin: 0;
  }
  img {
    height: auto;
    width: 70px;
    margin-left: 15px;
  }
  @media (max-width: 1220px) {
    max-width: 700px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 0 15px;
    p {
      text-align: center;
      margin-bottom: 15px;
    }
    img {
      margin: 0 auto;
    }
  }
`;
