import React, { useState } from 'react';
import { Nav } from './styles';

const Navigation = ({ lastActive, children }: any) => {
  const [menuActive] = useState(false);

  return (
    <Nav
      className={menuActive ? 'active' :''} 
    >
      <ul>
        {children}
      </ul>
    </Nav>
  );
};

export default Navigation;
