import { createGlobalStyle } from 'styled-components';
import NotoSans from '../assets/fonts/NotoSans-Regular.ttf';
import ColumbiaRegular from '../assets/fonts/Columbia-Regular.ttf';
import ColumbiaBold from '../assets/fonts/Columbia-Bold.ttf';
import { COLORS } from '../constants/colors';
import BgTL from '../assets/svgs/background/bg-top-left.svg';
import BgTR from '../assets/svgs/background/bg-top-right.svg';
import BgBL from '../assets/svgs/background/bg-bottom-left.svg';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'NotoSans';
    src: url(${NotoSans}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'ColumbiaRegular';
    src: url(${ColumbiaRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'ColumbiaBold';
    src: url(${ColumbiaBold}) format('truetype');
    font-weight: 700;
    font-style: bold;
  }
  body {
    position: relative;
    min-height: 100vh;
    font-family: 'NotoSans';
    font-size: 14px;
    line-height: 22px;
    color: ${COLORS.text};
    margin: 0;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 371px;
      width: 410px;
      background: 50% no-repeat url(${BgTL}), transparent;
      z-index: -1;
      @media (max-width: 800px) {
        background: transparent;
        width: auto;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 217px;
      width: 376px;
      background: 50% no-repeat url(${BgTR}), transparent;
      z-index: -1;
    }
  }
  #root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    min-height: 100vh;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 159px;
      width: 323px;
      background: 50% no-repeat url(${BgBL}), transparent;
      z-index: -1;
    }
  }
  h1 {
    font-family: 'ColumbiaBold';
    font-size: 30px;
  }
  h2 {
    font-family: 'ColumbiaBold';
    font-size: 16px;
    line-height: 26px;
  }
  h3, h4, h5 {
    font-family: 'ColumbiaBold';
  }
  strong {
    font-family: 'ColumbiaBold';
    font-size: 24px;
    line-height: 40px;
    color: ${COLORS.highlightedText};
  }
  small {
    font-family: 'NotoSans';
    font-size: 14px;
    line-height: 16px;
  }
`;
 
export default GlobalStyle;
