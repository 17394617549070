import React from 'react';

import { IContentProps } from './interface';
import { ContentWrap, H1 } from './styles';

const AdditionalContent = ({
  content
}: IContentProps) => {

  return (
    <ContentWrap>
      <H1>{content.title}</H1>
      <div
        dangerouslySetInnerHTML={{
          __html: content.html
        }}
      ></div>
    </ContentWrap>
  );
};

export default AdditionalContent;
