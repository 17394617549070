import React, {createContext, useReducer} from 'react';
import { QuestionsReducer } from '../reducers'
import { IContent } from '../components/content/interface'

const initialState: any = {
  questions: [],
  current: 0,
  contentToShow: {} as IContent,
  error: null
};

const Store = ({ children }: any) => {
  const [state, dispatch] = useReducer(QuestionsReducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  )
};

export const Context = createContext(initialState);
export default Store;
