import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import Store from './store'
import GlobalStyle from './styles/global';
import Footer from './components/footer/Footer';
import Home from './pages/Home';
import Questionnaire from './pages/Questionnaire';
import Result from './pages/Result';

 const App = () => {
  return (
    <Store>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route exact path='/questionnaire'>
            <Questionnaire />
            <Footer />
          </Route>
          <Route exact path='/result'>
            <Result />
            <Footer textIsHidden={true} />
          </Route>
        </Switch>
      </Router>
    </Store>
  );
}

export default App;
