import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  padding: 60px 15px 54px;
  margin: 0 auto;
  @media (max-width: 1220px) {
    width: auto;
    max-width: 700px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Text = styled.div`
  font-family: 'ColumbiaBold';
  font-size: 16px;
  @media (max-width: 767px) {
    text-align: center;
    margin-bottom: 15px;
  }
`;

export const Logo = styled.img`
  height: auto;
  width: 70px;
  margin-left: 15px;
`;
