export const COLORS = {
  primary: '#FFD863',
  primaryDarken: '#fed045',
  lightGrey: '#EFEFEF',
  navigation: '#F4F4F4',
  text: '#000',
  highlightedText: '#2B3036',
  link: '#55845F',
  borderBtn: '#E0E6E2',
  borderInput: '#979797',
  separators: '#E1E1E1',
  errorBg: '#FAD2D2',
  errorText: '#FF0000',
};
