import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import { Context } from '../../store';
import { content } from '../../constants/urls';
import { WVPP } from '../../constants/texts';
import { IContent } from '../../components/content/interface';
import { IFooterProps } from './interface';
import { Container, Text, Logo } from './styles';

const Footer = ({
  textIsHidden
}: IFooterProps) => {

  const [contToShowFooter, setContToShowFooter]: [
    IContent,
    React.Dispatch<React.SetStateAction<IContent>>
  ] = useState({} as IContent);

  const [state] = useContext(Context);
  const currentQuestion = state.questions[state.current];

  useEffect(() => {
    if (Object.keys(state.contentToShow).length && state.contentToShow.footerContent) {
      axios.get(`${process.env.REACT_APP_API_URL}${content}/${state.contentToShow.footerContent}`)
        .then(response => {
          setContToShowFooter(response.data);
        });
      }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Text>
        {
          !textIsHidden && (
            Object.keys(state.contentToShow).length &&  state.contentToShow.footerContent ?
              contToShowFooter.text :
              currentQuestion && state.questions.length && currentQuestion.footerContent?.text
          )
        }
      </Text>
      <Logo src='/images/WVPP-logo-grey.png' alt={WVPP} />
    </Container>
  );
};

export default Footer;
