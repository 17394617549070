import React from 'react';
import { HER } from '../../constants/texts';
import { LogoWrap } from './styles';

const Logo = () => {
  return (
    <a href='/'><LogoWrap src='/images/HER-logo.svg' alt={HER} /></a>
  );
};

export default Logo;
