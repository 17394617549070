import React, { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { HER, WVPP } from '../../constants/texts';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { BgWrapper, Container, Logo, Footer } from './styles';

const Home = () => {
  const history = useHistory();
 
  const findResourcesClick = async (e: MouseEvent) => {
    history.push('/questionnaire');
  }

  return (
    <BgWrapper>
      <Container>
        <Logo src='/images/HER-logo-primary.svg' alt={HER} />
        <div>
          <p>
            <strong>West Virginia has an array of resources and guidance to offer providers serving mother-baby dyads with substance exposure.</strong>
          </p>
          <p>The goal of this site is to assist  providers in finding these resources for the women and children in their care.</p>
          <p>Answer a few questions about your case to receive a customized report of what’s available.</p>
        </div>
        <div>
          <PrimaryButton
            title='Get Started'
            handleClick={findResourcesClick}
          />
        </div>
        <div>
          <p>This tool is built and maintained by the WV Perinatal Partnership.</p>
        </div>
      </Container>
      <Footer>
        <p>This project was supported by the Centers for Medicare and Medicaid Services (CMS) of the U.S. Department of Health and Human Services (HHS) as part of a financial assistance award totaling $746,833 with 100% funded by CMS/HHS. The contents are those of the author(s) and do not necessarily represent the official views of, nor an endorsement, by CMS/HHS, or the U.S. Government.</p>
        <Logo src='/images/WVPP-logo-grey.png' alt={WVPP} />
      </Footer>
    </BgWrapper>
  );
}

export default Home;
