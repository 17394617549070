import React from 'react';
import { LoaderWrap } from './styles';

const Loader = () => {
  return (
    <LoaderWrap>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoaderWrap>
  );
};

export default Loader;
