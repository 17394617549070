import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { Context } from '../../store';
import { questionsList } from '../../constants/urls';
import Logo from '../../components/logo/Logo';
import Navigation from '../../components/navigation/Navigation';
import Loader from '../../components/loader/Loader';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import BackButton from '../../components/buttons/BackButton';
import AdditionalContent from '../../components/content/AdditionalContent';
import { IContent } from '../../components/content/interface';

import { AnswerType } from './interface';
import {
  Header,
  Container,
  AnswersContainer,
  Question,
  Answer,
  Counter
} from './styles';

const Questionnaire = () => {

  const [currAnswer, setCurrentAnswer]: [
    AnswerType,
    React.Dispatch<React.SetStateAction<AnswerType>>
  ] = useState([] as AnswerType);

  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}${questionsList}`)
      .then(response => {
        const questions = response.data.sort((a: any, b: any) => a.order - b.order);
        dispatch({ type: 'SET_QUESTIONS', payload: questions });
      })
      .catch(error => {
        dispatch({ type: 'SET_ERROR', payload: error });
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let isLoading = true;

  if (state.error || state.questions.length) {
    isLoading = false;
  }

  const currQuestion = state.current;
  const contentToShow = state.contentToShow;

  const selectAnswer = (i: number) => {
    if (currAnswer[currQuestion] === undefined) {
      currAnswer.push(new Set());
    }

    if (state.questions[currQuestion].choiceType === 'multiple') {
      if (currAnswer[currQuestion].has(i)) {
        currAnswer[currQuestion].delete(i);
      } else {
        currAnswer[currQuestion].add(i);
      }
    } else {
      if (currAnswer[currQuestion].size) {
        currAnswer[currQuestion].clear();
      }
      currAnswer[currQuestion].add(i);
    }

    setCurrentAnswer([...currAnswer]);
  };

  const nextQuestion = () => {
    if (state.questions.length === currQuestion + 1) {
      dispatch({ type: 'SET_CHOICES', payload: currAnswer });
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      history.push('/result');
    }

    let showNext = true;
    const answers = state.questions[currQuestion].answers;

    for (const answ of currAnswer[currQuestion]) {
      if (answers[answ].connectedContent) {
        dispatch({ type: 'SET_CONTENT_TO_SHOW', payload: answers[answ].connectedContent });
        showNext = false;
      }
    }

    if (showNext) dispatch({ type: 'SET_CURRENT_QUESTION', payload: currQuestion + 1 });
  };

  const prevQuestion = () => {
    if (Object.keys(contentToShow).length) {
      dispatch({ type: 'SET_CONTENT_TO_SHOW', payload: {} as IContent });
    } else {
      if (currQuestion === 0) {
        history.push('/')
      } else {
        dispatch({ type: 'SET_CURRENT_QUESTION', payload: currQuestion - 1 });
      }
    }
  };

  return (
    <>
      <Header>
        <BackButton
          title={Object.keys(contentToShow).length ? 'Back to Questionnaire' : 'Back'}
          handleClick={prevQuestion}
        />
        <Logo />
      </Header>
      <Container>
        { isLoading ? <Loader /> :
          ( state.questions.length && state.questions[currQuestion] ?
            <>
              { Object.keys(contentToShow).length ?
                <AdditionalContent
                  content={contentToShow}
                /> :
                <>
                  <Question>
                    <Counter>
                      0{currQuestion + 1}<span>/</span>
                      <small>0{state.questions.length}</small>
                    </Counter>
                    {state.questions[currQuestion].question}
                  </Question>
                  <AnswersContainer> 
                    <div
                      className={currQuestion === 0 ? 'risk-screening' : ''}
                    >
                      { state.questions[currQuestion].answers
                        .sort((a: any, b: any) => a.order - b.order)
                        .map((a: any, i: number) => {
                          return <Answer
                            key={`answer-${i}`}
                            className={
                              currAnswer[currQuestion] !== undefined &&
                              currAnswer[currQuestion].has(i) ? 'active' : ''
                            }
                            onClick={() => selectAnswer(i)}
                          >
                            <strong>{a.title}</strong>
                            <small>{a.secondaryText}</small>
                          </Answer>
                        })
                      }
                    </div>
                    <PrimaryButton
                      title={state.questions.length - 1 === currQuestion ?
                        'Generate Resources' : 'Next'
                      }
                      handleClick={nextQuestion}
                      classes={
                        currAnswer[currQuestion] !== undefined ?
                        [] : ['inactive']
                      }
                    />
                  </AnswersContainer>
                </>
              }
            </> :
            <div>Ooops... Something went wrong.</div>
          )
        }
      </Container>
      { Object.keys(contentToShow).length ?
        <></> : <Navigation>
          {!!state.questions.length && state.questions.map((q: any, i: number) => {
            return <li
              key={`question-${i}`}
              className={ currQuestion === i ? 'active' : '' }
            >
              <span>{q.title}</span>
            </li>
          })}
        </Navigation>
      }
    </>
  );
}

export default Questionnaire;
